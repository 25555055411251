<template>
  <base-layout>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2 md:justify-between">
          <title-plus  :title="$t('components.logsManagement.signInLogs.headline')" :hide-plus="true" />

          <div class="md:flex md:justify-end">
            <DateRangePicker
              :time-filter-enabled="true"
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>
      </div>
      <div
        class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row px-5"
      >
        <SummaryCard
          :title="$t('components.logsManagement.signInLogs.summary.Total')"
          :value="indexMetaData.summary.total || 0"
          variant="gray"
        />

        <SummaryCard
           :title="$t('components.logsManagement.signInLogs.summary.succeeded')"
          :value="indexMetaData.summary.succeeded || 0"
          variant="gray"
          valueVariant="green"
        />
        <SummaryCard
           :title="$t('components.logsManagement.signInLogs.summary.failed')"
          :value="indexMetaData.summary.failed || 0"
          variant="gray"
          valueVariant="red"
        />
      </div>

      <template>
        <FSTable
          :fst-id="fstId"
          :endpoint="indexDataEndpoint"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #topLeft="{ slotWidth }">
            <keep-alive>
              <FSTableFilter
                :fst-id="fstId"
                :slot-width="slotWidth"
                :options="filterOptions"
              />
            </keep-alive>
          </template>

          <template #default="{ data }">
            <TimelineGroup
              v-if="data.length > 0"
              :logs="data"
              timeline-type="smslogs"
            />
          </template>
        </FSTable>
      </template>
    </content-section>
  </base-layout>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import TimelineGroup from '@/components/timeline-new-final/TimelineGroup.vue'
import BaseLayout from '@/views/shared/BaseLayout.vue'
import {
  FSTable,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import { useEndpoints } from '@/composables'

export default {
  name: 'ViewSMSLogs',
  components: {
    ContentSection,
    TitlePlus,
    BaseLayout,
    DateRangePicker,
    FSTable,
    FSTableFilter,
    TimelineGroup,
    SummaryCard: () => import('@/components/cards/SummaryCard'),
  },
  data() {
    return {
      fstId: 'sms-log-index',
      qso: { append: '', prepend: '?' },
      indexMetaData: {
        summary: {},
      },
      indexDataEndpoint: useEndpoints.logs.smsLogsIndex(),
      orgData: [],
      filterOptions: [],
    }
  },
  created() {
    this.generateFilterOptions()
  },
  watch: {
    orgData: {
      deep: false,
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.filterOptions.unshift({
            key: 'organization',
            type: 'checkbox',
            input: val,
            title: 'Organization',
          })
        }
      },
    },
  },  
  methods: {
    onApplyFilterDateRange,
    async generateFilterOptions() {
      if (this.$user.is_superadmin) {
        await this.$http
          .get(useEndpoints.dropdown.organizations())
          .then((res) => {
            this.orgData = res.data.data.map((item) => {
              return {
                text: item.name,
                value: item.id,
              }
            })
            console.log('org-data', this.orgData)
          })
          .catch((err) => {
            console.log('org-data-err', { err })
            this.$notify({
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to retrieve Org data',
            })
          })
      }
      this.filterOptions = [
        {
          key: 'block_type',
          type: 'checkbox',
          input: [
            { text: 'Rate Limited', value: 'ratelimited' },
            {
              text: 'Repeated Unregistered Request',
              value: 'repeated_unregistered_request',
            },
          ],
          title: 'Block Type',
        },
        {
          key: 'status',
          type: 'checkbox',
          input: [
            { text: 'Success', value: 'succeeded' },
            { text: 'Failed', value: 'failed' },
          ],
          title: 'Status',
        },
      ]
    },
  },
}
</script>
