<template>
  <slide-over-right :id="sorId" :full-width="true">
    <loading :active.sync="isLoading"></loading>
    <div class="flex flex-wrap">
      <div class="w-1/2 md-max:w-full">
        <div class="flex items-center pl-8 mt-6 md-max:pl-4">
          <div
            class="items-center justify-center cursor-pointer back-button"
            @click="goBack"
          >
            <i class="fas fa-arrow-left"></i>
          </div>
          <h2 class="ml-3 font-semibold text-gray-900 text-22px">
            {{ titleText }}
          </h2>
        </div>

        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(confirmSubmit)" class="px-6">
            <div class="grid grid-cols-1 gap-2 mt-4 lg:grid-cols-2">
              <text-input
                rules="required"
                :name="
                  $t(
                    'components.restrictedAreaManagement.addEdit.steps.details.title.areaName'
                  )
                "
                :label="
                  $t(
                    'components.restrictedAreaManagement.addEdit.steps.details.title.areaName'
                  )
                "
                v-model="form.name"
              />
              <ValidationProvider
                :name="
                  $t(
                    'components.restrictedAreaManagement.addEdit.steps.details.title.assignedServiceArea'
                  )
                "
                rules="required"
                v-slot="{ errors }"
              >
                <input-label
                  :error="errors[0]"
                  :text="
                    $t(
                      'components.restrictedAreaManagement.addEdit.steps.details.title.assignedServiceArea'
                    )
                  "
                />
                <t-rich-select
                  name="options"
                  :options="service_areas"
                  valueAttribute="id"
                  textAttribute="name"
                  :hideSearchBox="false"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.geofence"
                />
                <div class="">
                  <input-error-item :message="errors[0]" />
                </div>
              </ValidationProvider>
            </div>

            <div class="flex items-end gap-2 mt-1">
              <div class="w-full">
                <PlacesAutocomplete
                  :label="
                    $t(
                      'components.restrictedAreaManagement.addEdit.steps.details.title.searchForLocation'
                    )
                  "
                  :disabled="!!!form.geofence"
                  :placeholder="
                    !!form.geofence === false
                      ? $t(
                          'components.restrictedAreaManagement.addEdit.steps.details.placeHolder.serviceAreaAtFirst'
                        )
                      : $t(
                          'components.restrictedAreaManagement.addEdit.steps.details.placeHolder.enterAddress'
                        )
                  "
                  @update:place="onUpdatingPlace"
                  @clear:place="() => (place = null)"
                />
              </div>
              <UploadGeoFile v-on:input="addKmlLayer" />
            </div>
            <div
              class="grid grid-cols-1 gap-2 mt-5 lg:grid-cols-2 lg:flex lg:items-end"
            >
              <div class="w-full">
                <ValidationProvider
                  :name="
                    $t(
                      'components.restrictedAreaManagement.addEdit.steps.details.title.allowInsideOfRestrictedAreaSpeedModeChange'
                    )
                  "
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input-label
                    :error="errors[0]"
                    :text="
                      $t(
                        'components.restrictedAreaManagement.addEdit.steps.details.title.allowInsideOfRestrictedAreaSpeedModeChange'
                      )
                    "
                    :isShowInfoSymbol="true"
                    :info="
                      $t(
                        'components.restrictedAreaManagement.addEdit.steps.details.infoDescription.allowInsideOfRestrictedAreaSpeedModeChange'
                      )
                    "
                  />
                  <t-rich-select
                    name="options"
                    :options="[
                      { value: true, text: 'Yes' },
                      { value: false, text: 'No' },
                    ]"
                    valueAttribute="value"
                    textAttribute="text"
                    :hideSearchBox="true"
                    :variant="{ danger: !!errors[0] }"
                    v-model="form.reset_speed_mode_for_entering"
                  />
                  <div class="">
                    <input-error-item :message="errors[0]" />
                  </div>
                </ValidationProvider>
              </div>

              <AppInput
                v-if="form.reset_speed_mode_for_entering"
                type="richselect"
                :name="
                  $t(
                    'components.restrictedAreaManagement.addEdit.steps.details.title.enterSpeedMode'
                  )
                "
                :label="
                  $t(
                    'components.restrictedAreaManagement.addEdit.steps.details.title.enterSpeedMode'
                  )
                "
                :placeholder="
                  $t(
                    'components.restrictedAreaManagement.addEdit.steps.details.placeHolder.enterSpeedMode'
                  )
                "
                :options="[
                  { value: 'L', text: 'Low' },
                  { value: 'M', text: 'Medium' },
                  { value: 'H', text: 'High' },
                ]"
                hide-search-box
                v-model="form.enter_speed_mode"
              />
            </div>
            <div class="grid grid-cols-1 gap-2 mt-2 lg:grid-cols-2">
              <div class="w-full ">
                <ValidationProvider
                  :name="
                    $t(
                      'components.restrictedAreaManagement.addEdit.steps.details.title.address'
                    )
                  "
                  rules=""
                  v-slot="{ errors }"
                >
                  <input-label
                    :error="errors[0]"
                    :text="
                      $t(
                        'components.restrictedAreaManagement.addEdit.steps.details.title.address'
                      )
                    "
                  />
                  <t-textarea
                    rules="required"
                    v-model="form.address"
                  ></t-textarea>
                  <input-error-item :message="errors[0]" />
                </ValidationProvider>
              </div>
              <div class="w-full">
                <input-label
                  :text="
                    $t(
                      'components.restrictedAreaManagement.addEdit.steps.details.title.note'
                    )
                  "
                ></input-label>
                <t-textarea
                  name="slowzoneAreaDescription"
                  v-model="form.description"
                />
                <input-error-item />
              </div>
            </div>
            <section>
              <div class="grid grid-cols-1 gap-2 mt-1 lg:grid-cols-2">
                <AppInput
                  type="richselect"
                  :name="
                    $t(
                      'components.restrictedAreaManagement.addEdit.steps.details.title.whenVehicleGoesInsideOfRestrictedAreaThrottle'
                    )
                  "
                  :label="
                    $t(
                      'components.restrictedAreaManagement.addEdit.steps.details.title.whenVehicleGoesInsideOfRestrictedAreaThrottle'
                    )
                  "
                  :placeholder="
                    $t(
                      'components.restrictedAreaManagement.addEdit.steps.details.placeHolder.whenVehicleGoesInsideOfRestrictedAreaThrottle'
                    )
                  "
                  value-attribute="value"
                  text-attribute="text"
                  :options="[
                    { text: 'On', value: true },
                    { text: 'Off', value: false },
                  ]"
                  hide-search-box
                  v-model="form.should_throttle"
                />
                <AppInput
                  type="richselect"
                  :name="
                    $t(
                      'components.restrictedAreaManagement.addEdit.steps.details.title.whenVehicleGoesInsideOfRestrictedAreaAlarm'
                    )
                  "
                  :label="
                    $t(
                      'components.restrictedAreaManagement.addEdit.steps.details.title.whenVehicleGoesInsideOfRestrictedAreaAlarm'
                    )
                  "
                  :placeholder="
                    $t(
                      'components.restrictedAreaManagement.addEdit.steps.details.placeHolder.whenVehicleGoesInsideOfRestrictedAreaAlarm'
                    )
                  "
                  value-attribute="value"
                  text-attribute="text"
                  :options="[
                    { text: 'Trigger', value: true },
                    { text: 'Do not Trigger', value: false },
                  ]"
                  hide-search-box
                  v-model="form.should_trigger"
                />
              </div>
            </section>

            <button type="submit" ref="submitButton" class="hidden">
              Save
            </button>
          </form>
        </ValidationObserver>

        <div class="flex items-center justify-end pr-6 mt-6">
          <slide-over-right-trigger :id="sorId">
            <anchor-button variant="secondary">
              {{ $t('components.stepNavigation.cancel') }}</anchor-button
            >
          </slide-over-right-trigger>
          <t-button type="submit" @click="submit()" class="ml-3">{{
            $t('components.stepNavigation.save')
          }}</t-button>
        </div>
      </div>

      <div class="w-1/2 md-max:w-full md-max:pt-6">
        <gmap-map
          ref="map"
          :zoom="zoom"
          :center="center"
          map-type-id="terrain"
          style="width: 100%; height: 100vh;"
        >
          <gmap-polygon
            :paths="parseToOverlay(form.coords)"
            :editable="true"
            :options="drawingOptions"
            @paths_changed="updateEdited($event)"
          >
          </gmap-polygon>
        </gmap-map>
      </div>
    </div>
  </slide-over-right>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { gmapApi } from 'vue2-google-maps'
import { UploadGeoFile } from '@/components/form'
import { useEndpoints } from '@/composables'
export default {
  name: 'AddEditRestrictedArea',
  components: {
    UploadGeoFile,
    SlideOverRight: () => import('@/components/modals/SlideOverRight'),
    SlideOverRightTrigger: () =>
      import('@/components/modals/SlideOverRightTrigger'),
    AnchorButton: () => import('@/components/form/AnchorButton'),
    TextInput: () => import('@/components/form/TextInput'),
    InputLabel: () => import('@/components/form/InputLabel'),
    PlacesAutocomplete: () => import('@/components/map/PlacesAutocomplete.vue'),
  },
  data() {
    return {
      isLoading: false,
      addTitle: this.$t(
        'components.restrictedAreaManagement.addEdit.headline.add'
      ),
      all_restricted_areas: [],
      restricted_areas: [],
      service_areas: [],
      slow_speed_areas: [],
      parking_areas: [],
      rent_areas: [],
      reward_zones: [],
      sunpod_station_areas: [],
      item: {},
      form: {
        address: '',
        coords: null,
        description: '',
        draw_type: null,
        geofence: null,
        name: null,
        should_throttle: null,
        should_trigger: null,
        reset_speed_mode_for_entering: null,
        enter_speed_mode: null,
      },
      center: {
        lat: 10,
        lng: 10,
      },
      errors: [],
      sorId: this.$config.restrictedArea.events.sorId,
      scrollwheel: true,
      zoom: 12,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: true,
      markers: [{}],
      edited: null,
      map: null,
      drawingManager: null,
      mapDrawingMode: 'Polygonal',
      parsedPolygon: null,
      drawingOptions: {
        fillColor: 'rgba(255, 0, 0, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#ff0000',
        clickable: true,
        draggable: true,
        editable: true, // TODO: make this work with wrapper func
        zIndex: 8,
      },
      serviceDrawingOptions: {
        fillColor: '#4aff5c20',
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: '#00b44d',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      parkingDrawingOptions: {
        fillColor: 'rgba(30, 152, 254, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#00acff',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 2,
      },
      slowSpeedDrawingOptions: {
        fillColor: 'rgba(156, 163, 175, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#9CA3AF',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 3,
      },
      restrictedDrawingOptions: {
        fillColor: 'rgba(255, 0, 0, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#ff0000',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 4,
      },
      rentDrawingOptions: {
        fillColor: 'rgba(88, 28, 135, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#581C87',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 5,
      },
      rewardZoneDrawingOptions: {
        fillColor: 'rgba(204, 204, 20, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#CCCC00',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 6,
      },
      sunpodStationDrawingOptions: {
        fillColor: 'rgba(190, 24, 93, 0.13',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#BE185D',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 7,
      },
      // places autocomplete
      theZoom: null,
      location_name: '',
      current_coords: '',
    }
  },
  computed: {
    google: gmapApi,
    drawingType: function() {
      return [
        {
          id: 'CIRCLE',
          name: 'Circle',
        },
        {
          id: 'POLYGON',
          name: 'Polygon',
        },
      ]
    },
    isEditing: function() {
      // enabled status-switch by default
      return Object.keys(this.item).length > 1
    },
    titleText: function() {
      return this.isEditing
        ? this.$t('components.restrictedAreaManagement.addEdit.headline.update')
        : this.addTitle
    },
    actionButtonText: function() {
      return this.isEditing ? 'Update' : 'Save'
    },
    getServiceArea: function() {
      return this.form.geofence || undefined
    },
  },
  watch: {
    // parsedPolygon() {
    //   if (this.parsedPolygon) {
    //     this.bindPolygonClickListener(this.parsedPolygon)
    //   }
    // },
    getServiceArea(updatedID) {
      console.log('update = ', updatedID)

      if (!this.service_areas.length) return

      this.service_areas.forEach((item) => {
        if (item.id == updatedID) {
          if (this.isEditing) {
            this.current_coords = item.coords
          } else {
            if (!this.$refs.map) return
            this.$refs.map.$mapPromise.then((map) => {
              this.map = map
              this.removeOverlayMap()
              const overlay = this.parseToOverlay(item.coords)
              this.getServiceBounds(overlay)
              this.setServiceOverlayOnMap(overlay)
              this.setMapCenterToDrawingCenter(overlay, false)
              // save the current coords for places autocomplete
              this.current_coords = item.coords
              this.drawOtherZonesOnMap()
            })
          }
        } else {
          this.removeOverlayMap()
        }
      })
    },
  },
  async created() {
    await this.loadServiceDropdown()
  },
  mounted() {
    EventBus.$on(this.$config.restrictedArea.events.editingData, (item) => {
      this.theZoom = null
      if (item.id && typeof item.id !== 'undefined') {
        // original data
        this.item = item

        // editing data
        this.form = {
          address: this.item.address || '',
          coords: this.item.coords,
          description: this.item.description || '',
          draw_type: this.item.draw_type,
          geofence: this.item.geofence.id,
          name: this.item.name,
          should_throttle: this.item.should_throttle,
          should_trigger: this.item.should_trigger,
          reset_speed_mode_for_entering: this.item
            .reset_speed_mode_for_entering,
          enter_speed_mode: this.item.enter_speed_mode,
        }
        this.restricted_areas = this.all_restricted_areas.filter(
          (area) => area.id !== item.id
        )
      } else {
        this.form = {}
        this.item = {}
        this.restricted_areas = this.all_restricted_areas
        setTimeout(() => {
          if (!this.$refs.map) return
          this.$refs.map.$mapPromise.then((map) => {
            console.log('Hitting promise with', map)
            this.map = map
            this.initDrawingMode()
          })
        }, 400)
      }
    })

    this.getCurrentLocation()
    this.$log.warn('EditData:', this.form)
    this.$log.debug('Map Ref', this.$refs.map)
  },
  updated() {
    // var self = this
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been re-rendered
      if (!this.$refs.map) return

      // the zoom is set only by places auto complete,
      // allow centeting & zooming the map
      // if user is searching for a place
      if (this.theZoom) return

      this.$refs.map.$mapPromise.then((map) => {
        this.$log.debug('Hitting promise with', map)
        this.map = map

        if (this.isEditing) {
          //set service area view
          // todo: allow changing service area
          if (this.item.geofence.coords) {
            this.removeOverlayMap()

            const overlay = this.parseToOverlay(this.item.geofence.coords)
            this.getServiceBounds(overlay)
            this.setServiceOverlayOnMap(overlay)
            this.drawOtherZonesOnMap()
          }
          // set restricted area
          const overlay = this.parseToOverlay(this.form.coords)
          // this.setOverlayOnMap(overlay)
          this.setMapCenterToDrawingCenter(overlay, false)
        }
        this.initDrawingMode()
      })
    })
  },
  methods: {
    addKmlLayer(kmlCode) {
      let latLngPairs = []
      const kmlParser = new DOMParser()
      const xmlDocument = kmlParser.parseFromString(kmlCode, 'text/xml')

      const placemarks = xmlDocument.getElementsByTagName('Placemark')
      for (const placemark of placemarks) {
        const polygons = placemark.getElementsByTagName('Polygon')
        console.log('coordinatesElement', polygons)
        for (const polygon of polygons) {
          const outerBoundaryIs = polygon.getElementsByTagName(
            'outerBoundaryIs'
          )[0]
          const linearRing = outerBoundaryIs.getElementsByTagName(
            'LinearRing'
          )[0]
          const coordinatesElement = linearRing.getElementsByTagName(
            'coordinates'
          )[0]

          const coordinates = coordinatesElement.textContent.trim()
          const coordinateArray = coordinates.split(/[\s,]+/)

          // Create pairs of latitude and longitude

          for (let i = 0; i < coordinateArray.length; i += 3) {
            const lat = parseFloat(coordinateArray[i + 1])
            const lng = parseFloat(coordinateArray[i])
            latLngPairs.push({ lat, lng })
          }

          console.log('addKmlLayer-i', latLngPairs)
        }
      }

      this.form.coords = this.getCoordStrFromCoordArray(latLngPairs, false)
      console.log('addKmlLayer', this.form.coords)
      this.drawKMLArea(this.form.coords)
    },
    drawKMLArea(coords) {
      const overlay = this.parseToOverlay(coords)
      this.setOverlayOnMap(overlay)
      this.setMapCenterToDrawingCenter(overlay, false)
    },
    updateEdited(mvcArray) {
      let sPaths = ''
      for (let i = 0; i < mvcArray.getLength(); i++) {
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          let point = mvcArray.getAt(i).getAt(j)
          if (i === 0 && j === 0) {
            sPaths = sPaths.concat(`{${point.lat()},${point.lng()}}`)
          } else {
            sPaths = sPaths.concat(',', `{${point.lat()},${point.lng()}}`)
          }
        }
      }
      this.form.coords = sPaths
    },
    goBack() {
      this.theZoom = null
      dispatchEvent(new Event(this.$config.restrictedArea.events.sorToggle))
    },
    initDrawingMode() {
      if (this.drawingManager) {
        this.drawingManager.setMap(null)
      }
      this.drawingManager = new this.google.maps.drawing.DrawingManager({
        drawingMode: this.google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: this.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            this.google.maps.drawing.OverlayType.POLYGON,
            this.google.maps.drawing.OverlayType.CIRCLE,
          ],
        },
        // markerOptions: {
        //   icon:
        //     "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
        // },
        circleOptions: this.drawingOptions,
        polygonOptions: this.drawingOptions,
      })
      console.log('Manager ', this.drawingManager)
      this.drawingManager.setMap(this.map)
      this.google.maps.event.addListener(
        this.drawingManager,
        'overlaycomplete',
        this.overlayCompleted
      )
      // this.google.maps.event.addListener(
      //   this.drawingManager,
      //   "polygoncomplete",
      //   function(polygon) {
      //   }
      // );
      this.google.maps.event.addListener(
        this.drawingManager,
        'circlecomplete',
        this.circleCompleted
      )
    },
    getServiceBounds(overlay) {
      var bounds = new this.google.maps.LatLngBounds()
      for (const path of overlay) {
        bounds.extend(path)
      }
      this.service_bounds = bounds
    },
    setServiceOverlayOnMap(payload) {
      this.parsedServicePolygon = new this.google.maps.Polygon({
        paths: payload,
        ...this.serviceDrawingOptions,
      })
      this.parsedServicePolygon.setMap(this.map)
      console.log('po = ', this.parsedServicePolygon)
    },
    drawOtherZonesOnMap() {
      const otherAreaBounds = []
      const otherParsedPolygons = []
      this.restricted_areas.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.restrictedDrawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })
      this.sunpod_station_areas.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.sunpodStationDrawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })
      this.slow_speed_areas.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.slowSpeedDrawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })

      this.reward_zones.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.rewardZoneDrawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })

      this.parking_areas.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.parkingDrawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })

      this.rent_areas.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.rentDrawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })

      this.otherAreaBounds = otherAreaBounds
      this.otherParsedPolygons = otherParsedPolygons
    },
    removeOverlayMap() {
      if (this.parsedServicePolygon) this.parsedServicePolygon.setMap(null)

      if (this.parsedPolygon) this.parsedPolygon.setMap(null)

      if (this.otherParsedPolygons) {
        for (let i = 0; i < this.otherParsedPolygons.length; ++i) {
          this.otherParsedPolygons[i].setMap(null)
        }
      }
    },
    isAreaValid(overlay) {
      let inService = true
      let outOthers = true

      if (overlay) {
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        this.form.center_coords =
          bounds.getCenter().lat() + ',' + bounds.getCenter().lng()

        for (const path of overlay) {
          if (this.service_bounds && !this.service_bounds.contains(path)) {
            inService = false
          }

          if (!inService) {
            break
          }
        }
      }

      if (this.otherAreaBounds && this.areaBounds) {
        for (let i = 0; i < this.otherAreaBounds.length; ++i) {
          if (this.otherAreaBounds[i].intersects(this.areaBounds)) {
            outOthers = false
          }

          if (!outOthers) {
            break
          }
        }
      }

      outOthers = true

      if (!inService || !outOthers) {
        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: 'Restricted Area Update',
            text:
              'Please draw the area inside of Service Area and outside of other areas.',
          },
          3000
        )
      }

      return inService && outOthers
    },
    circleCompleted(event) {
      if (event.type == this.google.maps.drawing.OverlayType.Circle) {
        if (this.parsedPolygon) this.parsedPolygon.setMap(null)
        this.mapDrawingMode = 'Circular'
        this.form.draw_type = 'C'
        const coordStr = this.getCircleCoords(event)
        this.setDrawnCoords(coordStr, event)
      }
    },
    overlayCompleted(event) {
      if (event.type == this.google.maps.drawing.OverlayType.POLYGON) {
        if (this.parsedPolygon) this.parsedPolygon.setMap(null)
        // this.bindPolygonClickListener(event.overlay);
        this.mapDrawingMode = 'Polygonal'
        this.form.draw_type = 'P'
        const coordStr = this.getPolygonCoords(event)
        this.setDrawnCoords(coordStr, event)
      }
      if (event.type == this.google.maps.drawing.OverlayType.Circle) {
        if (this.parsedPolygon) this.parsedPolygon.setMap(null)
        this.mapDrawingMode = 'Circular'
        this.form.draw_type = 'C'
        const coordStr = this.getCircleCoords(event)
        this.setDrawnCoords(coordStr, event)
      }
    },
    bindPolygonClickListener(overlay) {
      console.log('Listener hit')
      var outerContext = this
      this.google.maps.event.addListener(overlay, 'mouseup', function(event) {
        console.log(event)
        console.log('Listener', overlay.getPath().getArray())
        const coordStr = outerContext.getCoordStrFromCoordArray(
          overlay.getPath().getArray()
        )
        // console.log("UPDATED FROM CUSTOM EVENT HANDLER", coordStr)
        outerContext.form.coords = coordStr
      })
    },
    getCircleCoords(circle) {
      var numPts = 20
      var path = []
      for (var k = 0; k < numPts; k++) {
        path.push(
          this.google.maps.geometry.spherical.computeOffset(
            circle.getCenter(),
            circle.getRadius(),
            (k * 360) / numPts
          )
        )
      }
      console.log('Circle Path', path)
      var dumpStr = ''
      for (var i = 0; i < path.length; i++) {
        console.log(path[i].lat())
        dumpStr += `{${path[i].lat()},${path[i].lng()}},`
      }
      console.log('Circle draw completed', dumpStr)
      return dumpStr
    },
    getCoordStrFromCoordArray(coordinatesArray, isNativePolygon = true) {
      var dumpStr = ''
      for (var item of coordinatesArray) {
        console.log(item)
        dumpStr += `{${isNativePolygon ? item.lat() : item.lat},${
          isNativePolygon ? item.lng() : item.lng
        }},`
      }
      console.log('Poly draw completed', dumpStr)
      return dumpStr
    },
    getPolygonCoords(polygon) {
      var coordinatesArray = polygon.overlay.getPath().getArray()
      return this.getCoordStrFromCoordArray(coordinatesArray, true)
    },
    setDrawnCoords(coords, sender) {
      // this is a tricky code--added for re-rendering
      if (
        this.addTitle ===
        `${this.$t(
          'components.restrictedAreaManagement.addEdit.headline.add'
        )} `
      ) {
        this.addTitle = `${this.$t(
          'components.restrictedAreaManagement.addEdit.headline.add'
        )}`
      } else {
        this.addTitle = `${this.$t(
          'components.restrictedAreaManagement.addEdit.headline.add'
        )} `
      }
      this.form.coords = coords
      console.log('setDrawnCoords', this.form.coords, sender)
      if (this.parsedPolygon) {
        this.parsedPolygon.setMap(null)
      }
      // this.drawingManager.setMap(null);
      if (this.parsedPolygon !== sender) {
        sender.overlay ? sender.overlay.setMap(null) : sender.setMap(null)
      }
      // this.initDrawingMode();
      var that = this
      // this.$nextTick(function() {
      //   const overlay = that.parseToOverlay(coords)
      //   that.setOverlayOnMap(overlay)
      //   // that.bindPolygonClickListener(sender.overlay);
      //   that.setMapCenterToDrawingCenter(overlay, true)
      // })
      this.$nextTick(function() {
        const overlay = that.parseToOverlay(coords)
        if (this.service_bounds != null && this.isAreaValid(overlay)) {
          // that.setOverlayOnMap(overlay)
          that.setMapCenterToDrawingCenter(overlay, true)
        }
      })
    },
    parseToOverlay(coords) {
      if (!coords) return
      var coordsMorph = coords.replaceAll('{', '')
      coordsMorph = coordsMorph.replaceAll('}', '')
      var coordsArr = coordsMorph.split(',')
      if (!Array.isArray(coordsArr) || !coordsArr.length % 2 === 0) {
        if (coordsArr[coordsArr.length - 1] == ',') {
          console.log('Hit pop')
          coordsArr.pop()
        }
      }
      // console.log("")
      var payload = []
      for (var i = 0; i < coordsArr.length; i = i + 2) {
        // console.log("lat", coordsArr[i])
        // console.log("lon", coordsArr[i+1])
        const lat = parseFloat(coordsArr[i])
        const lng = parseFloat(coordsArr[i + 1])
        if (isNaN(lat) || isNaN(lng)) continue
        payload.push({
          lat: lat,
          lng: lng,
        })
      }
      return payload
    },
    getBounds(overlay) {
      const bounds = new this.google.maps.LatLngBounds()
      for (const path of overlay) {
        bounds.extend(path)
      }

      return bounds
    },
    setOverlayOnMap(payload) {
      this.areaBounds = this.getBounds(payload)
      this.parsedPolygon = new this.google.maps.Polygon({
        paths: payload,
        ...this.drawingOptions,
      })

      this.parsedPolygon.setMap(this.map)

      // console.log(this.parsedPolygon.getPath().getArray())
      this.parsedPolygon.addListener('dragend', () => {
        const coordStr = this.getCoordStrFromCoordArray(
          this.parsedPolygon.getPath().getArray()
        )
        this.form.coords = coordStr
        this.areaBounds = this.getBounds(
          this.parsedPolygon.getPath().getArray()
        )
      })
      console.log('SET ON MAP')
    },
    // updatePolygonBounds(index, path, payload) {
    //   console.log('HIT UPDATE POLY...', payload[index])
    //   if (!this.parsedPolygon) return
    //   payload[index] = {
    //     lat: parseFloat(path.lat()),
    //     lng: parseFloat(path.lng()),
    //   }
    //   console.log('HIT UPDATED POLY...', {
    //     lat: parseFloat(path.lat()),
    //     lng: parseFloat(path.lng()),
    //   })
    //   // payload[index] = path
    //   const coordStr = this.getCoordStrFromCoordArray(payload, false)
    //   console.log('STORED COORDS', this.form.coords)
    //   console.log('DIFFED COORDS', coordStr)
    //   this.form.coords = coordStr
    //   console.log('STORED UPD', this.form.coords)
    //   // this.setDrawnCoords(coordStr, this.parsedPolygon)
    // },
    setMapCenterToDrawingCenter(
      paths,
      panToCenter = true,
      latlng = null,
      zoom = null
    ) {
      var bounds = new this.google.maps.LatLngBounds()
      for (const path of paths) {
        bounds.extend(path)
      }
      const newCenter = bounds.getCenter()
      console.log('NEW CENTER ', newCenter, panToCenter, latlng, zoom)

      if (panToCenter) {
        this.map.panTo(newCenter)
      }

      if (latlng) {
        this.center.lat = latlng.lat
        this.center.lng = latlng.lng
      } else {
        this.center.lat = newCenter.lat()
        this.center.lng = newCenter.lng()
      }

      if (zoom) {
        this.zoom = zoom
      } else {
        //reset map zoom based on new poly (can't see whole poly on render if the poly is bigger then the current set zoom)
        this.map.fitBounds(bounds)
      }
      // if (!this.parsedPolygon) return
      // const polyBounds = this.getPolygonBounds(this.parsedPolygon)
      // const newZoomLevel = this.getZoomLevelByBounds(this.map, polyBounds)
      // this.map.setZoom(newZoomLevel)
    },
    getCurrentLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position);
        this.center.lat = position.coords.latitude
        this.center.lng = position.coords.longitude
      })
    },
    checkValidPolygon(coordStr) {
      if (!coordStr) return false

      const coords = coordStr.split(',')
      return Array.isArray(coords) && coords.length > 2
    },
    submit: async function() {
      console.log('COORDS', this.form.coords)
      if (this.form.coords === undefined || this.form.coords === null) {
        this.isLoading = false
        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: 'Restricted Area Update',
            text: 'Please draw a valid geofence (Polygon/Circle)',
          },
          3000
        )
        return
      } else {
        this.isLoading = false
      }
      if (!this.checkValidPolygon(this.form.coords)) {
        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: 'Restricted Area Update',
            text:
              'Please draw a valid polygon containing more than two coordinates',
          },
          3000
        )
        return
      }
      this.$refs.submitButton.click()
    },
    confirmSubmit: async function() {
      if (this.areaBounds && !this.isAreaValid(null)) {
        return
      }

      this.isLoading = true

      if (!this.form.description) {
        await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.center.lat},${this.center.lng}&key=AIzaSyC6mg5slEqKmJxXDo6kLgYtHXg_S0AQJ4A`
        )
          .then((res) => res.json())
          .then((res) => {
            this.saveData(res.results[0].formatted_address)
          })
          .catch(() => this.saveData(`${this.center.lat},${this.center.lng}`))
      } else {
        await this.saveData()
      }
    },
    async saveData(description = '') {
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? this.$config.restrictedArea.api.update(this.item.id)
        : this.$config.restrictedArea.api.create
      const formDataProxy = {
        ...this.form,
        address: this.form.address ? this.form.address : '',
        description: this.form.description
          ? this.form.description
          : `${description}`,
      }
      let data = new xMan(formDataProxy).toFormData()
      let message = this.isEditing
        ? 'Restricted Area updated successfully'
        : 'Restricted Area added successfully'

      try {
        let response = await this.$http({
          method,
          url,
          data,
        })

        await this.loadServiceDropdown()

        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(this.$config.restrictedArea.events.sorToggle))
        // Refetch the indexData
        dispatchEvent(new Event(this.$config.restrictedArea.events.refresh))
        // Reset the form
        this.form = {}

        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Success',
            text: message,
          },
          3000
        )

        return response.status
      } catch (error) {
        this.isLoading = false

        const errResponse = error.response ? error.response.data : null
        message = 'Invalid restricted area'

        if (errResponse && errResponse.length) {
          message = errResponse[0]
        }

        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: 'Restricted Area Update',
            text: message,
          },
          3000
        )
      }
    },
    async onUpdatingPlace(data) {
      var lat = data.geometry.location.lat().toString()
      var lng = data.geometry.location.lng().toString()

      this.$log.debug('lat', lat, lng)

      const center = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      }

      const overlay = this.parseToOverlay(this.current_coords)
      this.$log.debug(overlay)

      this.getServiceBounds(overlay)
      this.setServiceOverlayOnMap(overlay)
      // varying zoom
      this.theZoom = 18
      if (this.zoom === this.theZoom) {
        this.theZoom = 17
      } else {
        this.theZoom = 18
      }
      this.setMapCenterToDrawingCenter(overlay, false, center, this.theZoom)
      // this.initDrawingMode()

      // this.zoom = 50
    },
    async loadServiceDropdown() {
      // console.log('service = ', this.$config.dorpdown.api.serviceAreas)
      const areasDataAPICalls = [
        this.$http.get(`${this.$config.serviceArea.api.index}?export=true`),
        this.$http.get(`${this.$config.slowSpeedArea.api.index}?export=true`),
        this.$http.get(`${this.$config.parkingArea.api.index}?export=true`),
        this.$http.get(`${this.$config.restrictedArea.api.index}?export=true`),
        this.$http.get(
          `${useEndpoints.geoFence.rentStationArea.index()}?export=true`
        ),
        this.$http.get(`${this.$config.rewardZone.api.index}?export=true`),
        this.$http.get(
          `${this.$config.sunpodStationArea.api.index}?export=true`
        ),
      ]
      try {
        const responses = await Promise.all(areasDataAPICalls)
        this.service_areas = responses[0].data.data
        this.slow_speed_areas = responses[1].data.data
        this.parking_areas = responses[2].data.data
        this.all_restricted_areas = responses[3].data.data
        this.restricted_areas = responses[3].data.data
        this.rent_areas = responses[4].data.data
        this.reward_zones = responses[5].data.data
        this.sunpod_station_areas = responses[6].data.data
      } catch (err) {
        console.log('Error loading areas data', err)
      }
    },
    getAddressData(data) {
      var lat = data.geometry.location.lat().toString()
      var lng = data.geometry.location.lng().toString()
      console.log('lat = ', lat, lng)
      this.center = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      }
    },
    async onChangeServiceArea(event) {
      console.log('Hit change service area..', event)
      // this.serviceAreaFleets.forEach(element => {
      //   if (element.id == event) {
      //     console.log(this.serviceAreaFleets);
      //   }
      // });
      await this.$http
        .get(this.$config.serviceArea.api.details(event))
        .then((res) => {
          const centerCoords = res.data.center_coords.split(',')
          this.center = {
            lat: parseFloat(centerCoords[0]),
            lng: parseFloat(centerCoords[1]),
          }
        })
        .catch((err) => {
          console.log('err = ', err.message)
        })
    },
    /*
    getPolygonBounds(polygon) {
      var bounds = new this.google.maps.LatLngBounds();
      var paths = polygon.getPaths();
      var path;
      for (var i = 0; i < paths.getLength(); i++) {
        path = paths.getAt(i);
        for (var ii = 0; ii < path.getLength(); ii++) {
          bounds.extend(path.getAt(ii));
        }
      }
      return bounds;
    },
    getZoomLevelByBounds(map, bounds) {
      var MAX_ZOOM = 20;
      var MIN_ZOOM = 3;

      var ne = map.getProjection().fromLatLngToPoint(bounds.getNorthEast());
      var sw = map.getProjection().fromLatLngToPoint(bounds.getSouthWest());

      var worldCoordWidth = Math.abs(ne.x - sw.x);
      var worldCoordHeight = Math.abs(ne.y - sw.y);

      //Fit padding in pixels
      var FIT_PAD = 40;

      for (var zoom = MAX_ZOOM; zoom >= MIN_ZOOM; --zoom) {
        if (
          worldCoordWidth * (1 << zoom) + 2 * FIT_PAD <
            this.map.getDiv().offsetWidth &&
          worldCoordHeight * (1 << zoom) + 2 * FIT_PAD <
            this.map.getDiv().offsetheight
        )
          return zoom;
      }
      return 0;
    },
    */
  },
}
</script>

<style scoped>
.toggle-button-active {
  border: 1px solid #1bca09;
  width: 100%;
  height: 140px;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
}
.toggle-button {
  width: 100%;
  height: 140px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
}
.toggle-title {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.toggle-description {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 10px;
  @apply text-gray-600;
}
.back-button {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  box-shadow: 1px 2px 3px 1px #d0caca;
  display: flex;
}
.back-button:hover {
  opacity: 0.8;
}
.google-auto-complete {
  width: 100%;
}
.google-auto-complete div {
  width: 100%;
  height: 38px;
}
</style>
